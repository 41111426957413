@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

$color_1: #000000;
$color_2: #666666;
$font-family_1: "Calibri";
$font-family_2: "Georgia";
$background-color_1: #ffffff;

.termsAndConditions{
  .lst-kix_list_33-5 {
    >li {
      counter-increment: lst-ctn-kix_list_33-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_33-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-1 {
    >li {
      counter-increment: lst-ctn-kix_list_2-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_2-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_21-8 {
    >li {
      counter-increment: lst-ctn-kix_list_21-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_21-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_33-5.start {
    counter-reset: lst-ctn-kix_list_33-5 0;
  }
  ol.lst-kix_list_9-0.start {
    counter-reset: lst-ctn-kix_list_9-0 0;
  }
  ol.lst-kix_list_30-0.start {
    counter-reset: lst-ctn-kix_list_30-0 17;
  }
  ol.lst-kix_list_13-4.start {
    counter-reset: lst-ctn-kix_list_13-4 0;
  }
  .lst-kix_list_30-6 {
    >li {
      counter-increment: lst-ctn-kix_list_30-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_30-6, decimal) ". ";
      }
    }
  }
  .lst-kix_list_13-0 {
    >li {
      counter-increment: lst-ctn-kix_list_13-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_20-2.start {
    counter-reset: lst-ctn-kix_list_20-2 0;
  }
  .lst-kix_list_5-0 {
    >li {
      counter-increment: lst-ctn-kix_list_5-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }
  ol.lst-kix_list_7-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_23-2.start {
    counter-reset: lst-ctn-kix_list_23-2 0;
  }
  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0;
  }
  .lst-kix_list_4-3 {
    >li {
      counter-increment: lst-ctn-kix_list_4-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_17-1.start {
    counter-reset: lst-ctn-kix_list_17-1 0;
  }
  .lst-kix_list_29-8 {
    >li {
      counter-increment: lst-ctn-kix_list_29-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_29-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_24-7 {
    >li {
      counter-increment: lst-ctn-kix_list_24-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_24-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_30-5.start {
    counter-reset: lst-ctn-kix_list_30-5 0;
  }
  .lst-kix_list_18-8 {
    >li {
      counter-increment: lst-ctn-kix_list_18-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_18-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_8-8.start {
    counter-reset: lst-ctn-kix_list_8-8 0;
  }
  ol.lst-kix_list_26-7.start {
    counter-reset: lst-ctn-kix_list_26-7 0;
  }
  ol.lst-kix_list_10-4.start {
    counter-reset: lst-ctn-kix_list_10-4 0;
  }
  .lst-kix_list_7-2 {
    >li {
      counter-increment: lst-ctn-kix_list_7-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_7-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_32-8 {
    >li {
      counter-increment: lst-ctn-kix_list_32-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_32-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_16-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 {
    >li {
      counter-increment: lst-ctn-kix_list_1-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_1-4, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_16-6 {
    list-style-type: none;
  }
  .lst-kix_list_24-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_24-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_24-8;
    }
  }
  ol.lst-kix_list_16-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  ol.lst-kix_list_16-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-5.start {
    counter-reset: lst-ctn-kix_list_9-5 0;
  }
  ol.lst-kix_list_16-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-4 {
    list-style-type: none;
  }
  .lst-kix_list_24-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_24-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_24-2;
    }
  }
  ol.lst-kix_list_16-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-7.start {
    counter-reset: lst-ctn-kix_list_20-7 0;
  }
  .lst-kix_list_24-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_24-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_24-3;
    }
  }
  .lst-kix_list_24-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_24-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_24-4;
    }
  }
  .lst-kix_list_9-4 {
    >li {
      counter-increment: lst-ctn-kix_list_9-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_9-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_24-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_24-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_24-5;
    }
  }
  .lst-kix_list_24-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_24-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_24-6;
    }
  }
  ol.lst-kix_list_16-4.start {
    counter-reset: lst-ctn-kix_list_16-4 0;
  }
  ol.lst-kix_list_14-1.start {
    counter-reset: lst-ctn-kix_list_14-1 0;
  }
  .lst-kix_list_23-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-6;
    }
  }
  .lst-kix_list_6-5 {
    >li {
      counter-increment: lst-ctn-kix_list_6-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_6-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_27-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_27-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_27-8 {
    list-style-type: none;
  }
  .lst-kix_list_23-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-3;
    }
  }
  .lst-kix_list_23-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-7;
    }
  }
  ol.lst-kix_list_27-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_27-2 {
    list-style-type: none;
  }
  .lst-kix_list_23-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-2;
    }
  }
  ol.lst-kix_list_27-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_23-7.start {
    counter-reset: lst-ctn-kix_list_23-7 0;
  }
  ol.lst-kix_list_27-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_27-3 {
    list-style-type: none;
  }
  .lst-kix_list_23-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-0;
    }
  }
  .lst-kix_list_23-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-8;
    }
  }
  .lst-kix_list_3-6 {
    >li {
      counter-increment: lst-ctn-kix_list_3-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_27-0 {
    list-style-type: none;
  }
  .lst-kix_list_23-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-1;
    }
  }
  .lst-kix_list_24-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_24-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_24-1;
    }
  }
  .lst-kix_list_2-8 {
    >li {
      counter-increment: lst-ctn-kix_list_2-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_2-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_24-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_24-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_24-0;
    }
  }
  ol.lst-kix_list_26-2.start {
    counter-reset: lst-ctn-kix_list_26-2 0;
  }
  .lst-kix_list_23-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-4;
    }
  }
  .lst-kix_list_23-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_23-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_23-5;
    }
  }
  ol.lst-kix_list_31-2.start {
    counter-reset: lst-ctn-kix_list_31-2 0;
  }
  .lst-kix_list_22-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_22-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_22-2;
    }
  }
  .lst-kix_list_22-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_22-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_22-6;
    }
  }
  .lst-kix_list_8-6 {
    >li {
      counter-increment: lst-ctn-kix_list_8-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
      }
    }
  }
  .lst-kix_list_22-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_22-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_22-0;
    }
  }
  .lst-kix_list_22-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_22-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_22-8;
    }
  }
  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0;
  }
  ol.lst-kix_list_9-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-5.start {
    counter-reset: lst-ctn-kix_list_22-5 0;
  }
  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 2;
  }
  ol.lst-kix_list_9-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-4 {
    list-style-type: none;
  }
  .lst-kix_list_5-7 {
    >li {
      counter-increment: lst-ctn-kix_list_5-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_5-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_9-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_9-2 {
    list-style-type: none;
  }
  .lst-kix_list_22-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_22-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_22-4;
    }
  }
  ol.lst-kix_list_32-8.start {
    counter-reset: lst-ctn-kix_list_32-8 0;
  }
  ol.lst-kix_list_25-5.start {
    counter-reset: lst-ctn-kix_list_25-5 0;
  }
  ol.lst-kix_list_29-2.start {
    counter-reset: lst-ctn-kix_list_29-2 0;
  }
  .lst-kix_list_25-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_25-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_25-5;
    }
  }
  .lst-kix_list_25-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_25-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_25-7;
    }
  }
  .lst-kix_list_3-5 {
    >li {
      counter-increment: lst-ctn-kix_list_3-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_3-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }
  ol.lst-kix_list_18-3.start {
    counter-reset: lst-ctn-kix_list_18-3 0;
  }
  ol.lst-kix_list_24-4.start {
    counter-reset: lst-ctn-kix_list_24-4 0;
  }
  ol.lst-kix_list_18-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_18-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_33-0.start {
    counter-reset: lst-ctn-kix_list_33-0 20;
  }
  ol.lst-kix_list_18-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_18-4 {
    list-style-type: none;
  }
  .lst-kix_list_6-4 {
    >li {
      counter-increment: lst-ctn-kix_list_6-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_6-4, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_18-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_18-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_27-4.start {
    counter-reset: lst-ctn-kix_list_27-4 0;
  }
  ol.lst-kix_list_18-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_18-1 {
    list-style-type: none;
  }
  .lst-kix_list_9-3 {
    >li {
      counter-increment: lst-ctn-kix_list_9-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_18-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-2.start {
    counter-reset: lst-ctn-kix_list_15-2 0;
  }
  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }
  ol.lst-kix_list_7-6.start {
    counter-reset: lst-ctn-kix_list_7-6 0;
  }
  ol.lst-kix_list_29-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_29-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-3.start {
    counter-reset: lst-ctn-kix_list_15-3 0;
  }
  ol.lst-kix_list_29-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_29-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_29-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_29-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_29-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0;
  }
  ol.lst-kix_list_29-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_29-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 {
    >li {
      counter-increment: lst-ctn-kix_list_1-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_12-2.start {
    counter-reset: lst-ctn-kix_list_12-2 0;
  }
  ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0;
  }
  .lst-kix_list_4-2 {
    >li {
      counter-increment: lst-ctn-kix_list_4-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_4-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0;
  }
  .lst-kix_list_5-1 {
    >li {
      counter-increment: lst-ctn-kix_list_5-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_5-1, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_3-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-0.start {
    counter-reset: lst-ctn-kix_list_19-0 6;
  }
  ol.lst-kix_list_21-3.start {
    counter-reset: lst-ctn-kix_list_21-3 0;
  }
  ol.lst-kix_list_3-0 {
    list-style-type: none;
  }
  .lst-kix_list_7-1 {
    >li {
      counter-increment: lst-ctn-kix_list_7-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_7-1, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_25-6.start {
    counter-reset: lst-ctn-kix_list_25-6 0;
  }
  ol.lst-kix_list_32-4.start {
    counter-reset: lst-ctn-kix_list_32-4 0;
  }
  .lst-kix_list_26-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_26-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_26-5;
    }
  }
  .lst-kix_list_16-0 {
    >li {
      counter-increment: lst-ctn-kix_list_16-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_16-0, decimal) ". ";
      }
    }
  }
  .lst-kix_list_8-0 {
    >li {
      counter-increment: lst-ctn-kix_list_8-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) ". ";
      }
    }
  }
  .lst-kix_list_10-0 {
    >li {
      counter-increment: lst-ctn-kix_list_10-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_10-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_18-2.start {
    counter-reset: lst-ctn-kix_list_18-2 0;
  }
  ol.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-6 {
    list-style-type: none;
  }
  .lst-kix_list_26-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_26-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_26-8;
    }
  }
  ol.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_21-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_21-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_21-0;
    }
  }
  .lst-kix_list_26-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_26-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_26-1;
    }
  }
  .lst-kix_list_13-1 {
    >li {
      counter-increment: lst-ctn-kix_list_13-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_13-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_21-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_21-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_21-1;
    }
  }
  ol.lst-kix_list_15-8.start {
    counter-reset: lst-ctn-kix_list_15-8 0;
  }
  .lst-kix_list_26-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_26-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_26-4;
    }
  }
  .lst-kix_list_10-2 {
    >li {
      counter-increment: lst-ctn-kix_list_10-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_10-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_21-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_21-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_21-5;
    }
  }
  .lst-kix_list_21-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_21-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_21-4;
    }
  }
  .lst-kix_list_26-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_26-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_26-0;
    }
  }
  .lst-kix_list_31-4 {
    >li {
      counter-increment: lst-ctn-kix_list_31-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_31-4, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_7-2.start {
    counter-reset: lst-ctn-kix_list_7-2 0;
  }
  .lst-kix_list_31-2 {
    >li {
      counter-increment: lst-ctn-kix_list_31-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_31-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_19-5.start {
    counter-reset: lst-ctn-kix_list_19-5 0;
  }
  ol.lst-kix_list_12-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-3.start {
    counter-reset: lst-ctn-kix_list_26-3 0;
  }
  ol.lst-kix_list_12-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_27-8.start {
    counter-reset: lst-ctn-kix_list_27-8 0;
  }
  .lst-kix_list_25-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_25-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_25-1;
    }
  }
  .lst-kix_list_25-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_25-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_25-0;
    }
  }
  ol.lst-kix_list_10-8.start {
    counter-reset: lst-ctn-kix_list_10-8 0;
  }
  ol.lst-kix_list_31-1.start {
    counter-reset: lst-ctn-kix_list_31-1 0;
  }
  ol.lst-kix_list_7-1.start {
    counter-reset: lst-ctn-kix_list_7-1 0;
  }
  ol.lst-kix_list_23-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_23-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_23-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_23-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_23-2 {
    list-style-type: none;
  }
  .lst-kix_list_16-7 {
    >li {
      counter-increment: lst-ctn-kix_list_16-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_16-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_21-4.start {
    counter-reset: lst-ctn-kix_list_21-4 0;
  }
  ol.lst-kix_list_23-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_23-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_23-3 {
    list-style-type: none;
  }
  .lst-kix_list_27-7 {
    >li {
      counter-increment: lst-ctn-kix_list_27-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_27-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_23-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-6.start {
    counter-reset: lst-ctn-kix_list_20-6 0;
  }
  .lst-kix_list_13-8 {
    >li {
      counter-increment: lst-ctn-kix_list_13-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_13-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_2-2 {
    >li {
      counter-increment: lst-ctn-kix_list_2-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_2-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0;
  }
  .lst-kix_list_16-5 {
    >li {
      counter-increment: lst-ctn-kix_list_16-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_16-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_5-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-7 {
    >li {
      counter-increment: lst-ctn-kix_list_3-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_3-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_26-2 {
    >li {
      counter-increment: lst-ctn-kix_list_26-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_26-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_5-2 {
    list-style-type: none;
  }
  .lst-kix_list_22-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_22-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_22-5;
    }
  }
  .lst-kix_list_21-2 {
    >li {
      counter-increment: lst-ctn-kix_list_21-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_21-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_22-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_22-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_22-1;
    }
  }
  .lst-kix_list_27-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_27-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_27-4;
    }
  }
  .lst-kix_list_20-2 {
    >li {
      counter-increment: lst-ctn-kix_list_20-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_20-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_6-6 {
    >li {
      counter-increment: lst-ctn-kix_list_6-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_15-7.start {
    counter-reset: lst-ctn-kix_list_15-7 0;
  }
  .lst-kix_list_13-6 {
    >li {
      counter-increment: lst-ctn-kix_list_13-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_13-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_14-6.start {
    counter-reset: lst-ctn-kix_list_14-6 0;
  }
  ol.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-8.start {
    counter-reset: lst-ctn-kix_list_26-8 0;
  }
  ol.lst-kix_list_5-3 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 {
    >li {
      counter-increment: lst-ctn-kix_list_8-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_8-7, decimal) ". ";
      }
    }
  }
  .lst-kix_list_19-6 {
    >li {
      counter-increment: lst-ctn-kix_list_19-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_19-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_5-4 {
    list-style-type: none;
  }
  .lst-kix_list_27-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_27-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_27-0;
    }
  }
  ol.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_20-4 {
    >li {
      counter-increment: lst-ctn-kix_list_20-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_20-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_9-5 {
    >li {
      counter-increment: lst-ctn-kix_list_9-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_9-5, decimal) ". ";
      }
    }
  }
  .lst-kix_list_5-8 {
    >li {
      counter-increment: lst-ctn-kix_list_5-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_5-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_19-4.start {
    counter-reset: lst-ctn-kix_list_19-4 0;
  }
  .lst-kix_list_32-0 {
    >li {
      counter-increment: lst-ctn-kix_list_32-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_32-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_33-6.start {
    counter-reset: lst-ctn-kix_list_33-6 0;
  }
  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }
  ol.lst-kix_list_20-1.start {
    counter-reset: lst-ctn-kix_list_20-1 0;
  }
  .lst-kix_list_25-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_25-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_25-4;
    }
  }
  .lst-kix_list_19-4 {
    >li {
      counter-increment: lst-ctn-kix_list_19-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_19-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_25-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_25-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_25-8;
    }
  }
  ol.lst-kix_list_25-1.start {
    counter-reset: lst-ctn-kix_list_25-1 0;
  }
  ol.lst-kix_list_14-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-6.start {
    counter-reset: lst-ctn-kix_list_26-6 0;
  }
  ol.lst-kix_list_14-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_21-8.start {
    counter-reset: lst-ctn-kix_list_21-8 0;
  }
  ol.lst-kix_list_14-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-6 {
    list-style-type: none;
  }
  .lst-kix_list_15-2 {
    >li {
      counter-increment: lst-ctn-kix_list_15-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_15-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_14-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-1 {
    list-style-type: none;
  }
  .lst-kix_list_27-5 {
    >li {
      counter-increment: lst-ctn-kix_list_27-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_27-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_14-2 {
    list-style-type: none;
  }
  .lst-kix_list_20-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_20-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_20-5;
    }
  }
  .lst-kix_list_28-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_28-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_28-8;
    }
  }
  .lst-kix_list_20-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_20-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_20-1;
    }
  }
  .lst-kix_list_12-3 {
    >li {
      counter-increment: lst-ctn-kix_list_12-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_12-3, decimal) ". ";
      }
    }
  }
  .lst-kix_list_17-3 {
    >li {
      counter-increment: lst-ctn-kix_list_17-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_17-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_8-4.start {
    counter-reset: lst-ctn-kix_list_8-4 0;
  }
  .lst-kix_list_28-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_28-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_28-4;
    }
  }
  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0;
  }
  .lst-kix_list_28-3 {
    >li {
      counter-increment: lst-ctn-kix_list_28-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_28-3, decimal) ". ";
      }
    }
  }
  .lst-kix_list_21-7 {
    >li {
      counter-increment: lst-ctn-kix_list_21-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_21-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_25-0.start {
    counter-reset: lst-ctn-kix_list_25-0 12;
  }
  ol.lst-kix_list_25-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_25-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_25-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_25-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-0.start {
    counter-reset: lst-ctn-kix_list_13-0 0;
  }
  .lst-kix_list_14-4 {
    >li {
      counter-increment: lst-ctn-kix_list_14-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_14-4, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_25-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_32-3.start {
    counter-reset: lst-ctn-kix_list_32-3 0;
  }
  ol.lst-kix_list_25-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_25-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_25-2 {
    list-style-type: none;
  }
  .lst-kix_list_10-7 {
    >li {
      counter-increment: lst-ctn-kix_list_10-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_10-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_25-1 {
    list-style-type: none;
  }
  .lst-kix_list_18-1 {
    >li {
      counter-increment: lst-ctn-kix_list_18-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_18-1, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_8-3.start {
    counter-reset: lst-ctn-kix_list_8-3 0;
  }
  .lst-kix_list_11-5 {
    >li {
      counter-increment: lst-ctn-kix_list_11-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_11-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_14-5.start {
    counter-reset: lst-ctn-kix_list_14-5 0;
  }
  .lst-kix_list_28-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_28-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_28-0;
    }
  }
  .lst-kix_list_32-7 {
    >li {
      counter-increment: lst-ctn-kix_list_32-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_32-7, decimal) ". ";
      }
    }
  }
  .lst-kix_list_27-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_27-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_27-8;
    }
  }
  ol.lst-kix_list_20-3.start {
    counter-reset: lst-ctn-kix_list_20-3 0;
  }
  .lst-kix_list_29-1 {
    >li {
      counter-increment: lst-ctn-kix_list_29-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_29-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_4-1 {
    >li {
      counter-increment: lst-ctn-kix_list_4-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_4-1, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_32-7.start {
    counter-reset: lst-ctn-kix_list_32-7 0;
  }
  .lst-kix_list_19-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_19-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_19-1;
    }
  }
  .lst-kix_list_31-5 {
    >li {
      counter-increment: lst-ctn-kix_list_31-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_31-5, decimal) ". ";
      }
    }
  }
  .lst-kix_list_19-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_19-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_19-3;
    }
  }
  .lst-kix_list_15-0 {
    >li {
      counter-increment: lst-ctn-kix_list_15-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_15-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_24-5.start {
    counter-reset: lst-ctn-kix_list_24-5 0;
  }
  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0;
  }
  .lst-kix_list_32-6 {
    >li {
      counter-increment: lst-ctn-kix_list_32-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_32-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_29-6.start {
    counter-reset: lst-ctn-kix_list_29-6 0;
  }
  .lst-kix_list_11-0 {
    >li {
      counter-increment: lst-ctn-kix_list_11-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_11-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  ol.lst-kix_list_9-6.start {
    counter-reset: lst-ctn-kix_list_9-6 0;
  }
  ol.lst-kix_list_16-3.start {
    counter-reset: lst-ctn-kix_list_16-3 0;
  }
  ol.lst-kix_list_27-5.start {
    counter-reset: lst-ctn-kix_list_27-5 0;
  }
  ol.lst-kix_list_22-4.start {
    counter-reset: lst-ctn-kix_list_22-4 0;
  }
  .lst-kix_list_22-7 {
    >li {
      counter-increment: lst-ctn-kix_list_22-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_22-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0;
  }
  .lst-kix_list_26-7 {
    >li {
      counter-increment: lst-ctn-kix_list_26-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_26-7, decimal) ". ";
      }
    }
  }
  .lst-kix_list_33-7 {
    >li {
      counter-increment: lst-ctn-kix_list_33-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_33-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_11-2.start {
    counter-reset: lst-ctn-kix_list_11-2 0;
  }
  .lst-kix_list_5-2 {
    >li {
      counter-increment: lst-ctn-kix_list_5-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_5-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_8-7.start {
    counter-reset: lst-ctn-kix_list_8-7 0;
  }
  .lst-kix_list_17-2 {
    >li {
      counter-increment: lst-ctn-kix_list_17-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_17-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_32-2 {
    >li {
      counter-increment: lst-ctn-kix_list_32-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_32-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_28-5 {
    >li {
      counter-increment: lst-ctn-kix_list_28-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_28-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_31-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_24-0.start {
    counter-reset: lst-ctn-kix_list_24-0 11;
  }
  .lst-kix_list_18-3 {
    >li {
      counter-increment: lst-ctn-kix_list_18-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_18-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_33-4.start {
    counter-reset: lst-ctn-kix_list_33-4 0;
  }
  .lst-kix_list_21-6 {
    >li {
      counter-increment: lst-ctn-kix_list_21-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_21-6, decimal) ". ";
      }
    }
  }
  .lst-kix_list_10-3 {
    >li {
      counter-increment: lst-ctn-kix_list_10-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }
  .lst-kix_list_18-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_18-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_18-0;
    }
  }
  ol.lst-kix_list_13-3.start {
    counter-reset: lst-ctn-kix_list_13-3 0;
  }
  .lst-kix_list_3-0 {
    >li {
      counter-increment: lst-ctn-kix_list_3-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
      }
    }
  }
  .lst-kix_list_18-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_18-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_18-2;
    }
  }
  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 3;
  }
  ol.lst-kix_list_27-0.start {
    counter-reset: lst-ctn-kix_list_27-0 14;
  }
  ol.lst-kix_list_11-7.start {
    counter-reset: lst-ctn-kix_list_11-7 0;
  }
  ol.lst-kix_list_14-2.start {
    counter-reset: lst-ctn-kix_list_14-2 0;
  }
  .lst-kix_list_16-1 {
    >li {
      counter-increment: lst-ctn-kix_list_16-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_16-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_30-4 {
    >li {
      counter-increment: lst-ctn-kix_list_30-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_30-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_27-1 {
    >li {
      counter-increment: lst-ctn-kix_list_27-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_27-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_25-2 {
    >li {
      counter-increment: lst-ctn-kix_list_25-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_25-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_27-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_27-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_27-3;
    }
  }
  .lst-kix_list_17-5 {
    >li {
      counter-increment: lst-ctn-kix_list_17-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_17-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_9-4.start {
    counter-reset: lst-ctn-kix_list_9-4 0;
  }
  .lst-kix_list_21-3 {
    >li {
      counter-increment: lst-ctn-kix_list_21-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_21-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_27-3.start {
    counter-reset: lst-ctn-kix_list_27-3 0;
  }
  .lst-kix_list_17-6 {
    >li {
      counter-increment: lst-ctn-kix_list_17-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_17-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_30-1.start {
    counter-reset: lst-ctn-kix_list_30-1 0;
  }
  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0;
  }
  .lst-kix_list_7-8 {
    >li {
      counter-increment: lst-ctn-kix_list_7-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_7-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_10-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_10-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_10-5;
    }
  }
  ol.lst-kix_list_13-5.start {
    counter-reset: lst-ctn-kix_list_13-5 0;
  }
  ol.lst-kix_list_18-6.start {
    counter-reset: lst-ctn-kix_list_18-6 0;
  }
  .lst-kix_list_29-3 {
    >li {
      counter-increment: lst-ctn-kix_list_29-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_29-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_20-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-8.start {
    counter-reset: lst-ctn-kix_list_13-8 0;
  }
  ol.lst-kix_list_20-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-7 {
    list-style-type: none;
  }
  .lst-kix_list_11-7 {
    >li {
      counter-increment: lst-ctn-kix_list_11-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_11-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_20-6 {
    list-style-type: none;
  }
  .lst-kix_list_9-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_9-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_9-2;
    }
  }
  ol.lst-kix_list_20-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-0.start {
    counter-reset: lst-ctn-kix_list_14-0 0;
  }
  .lst-kix_list_12-5 {
    >li {
      counter-increment: lst-ctn-kix_list_12-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_12-5, decimal) ". ";
      }
    }
  }
  .lst-kix_list_5-5 {
    >li {
      counter-increment: lst-ctn-kix_list_5-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_5-5, decimal) ". ";
      }
    }
  }
  .lst-kix_list_31-1 {
    >li {
      counter-increment: lst-ctn-kix_list_31-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_31-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_9-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_9-0;
    }
  }
  .lst-kix_list_16-8 {
    >li {
      counter-increment: lst-ctn-kix_list_16-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_16-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_33-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_24-3.start {
    counter-reset: lst-ctn-kix_list_24-3 0;
  }
  ol.lst-kix_list_33-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_33-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_33-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_33-2 {
    list-style-type: none;
  }
  .lst-kix_list_11-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_11-3;
    }
  }
  ol.lst-kix_list_33-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_33-4 {
    list-style-type: none;
  }
  .lst-kix_list_6-3 {
    >li {
      counter-increment: lst-ctn-kix_list_6-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_18-4.start {
    counter-reset: lst-ctn-kix_list_18-4 0;
  }
  ol.lst-kix_list_33-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }
  ol.lst-kix_list_33-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_29-1.start {
    counter-reset: lst-ctn-kix_list_29-1 0;
  }
  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }
  ol.lst-kix_list_22-7.start {
    counter-reset: lst-ctn-kix_list_22-7 0;
  }
  ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0;
  }
  .lst-kix_list_9-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_9-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_9-8;
    }
  }
  .lst-kix_list_28-6 {
    >li {
      counter-increment: lst-ctn-kix_list_28-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_28-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_16-8.start {
    counter-reset: lst-ctn-kix_list_16-8 0;
  }
  ol.lst-kix_list_33-1.start {
    counter-reset: lst-ctn-kix_list_33-1 0;
  }
  .lst-kix_list_28-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_28-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_28-7;
    }
  }
  .lst-kix_list_4-8 {
    >li {
      counter-increment: lst-ctn-kix_list_4-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_4-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_1-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_1-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_1-7;
    }
  }
  .lst-kix_list_1-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_1-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_1-5;
    }
  }
  ol.lst-kix_list_9-1.start {
    counter-reset: lst-ctn-kix_list_9-1 0;
  }
  ol.lst-kix_list_24-2.start {
    counter-reset: lst-ctn-kix_list_24-2 0;
  }
  .lst-kix_list_5-6 {
    >li {
      counter-increment: lst-ctn-kix_list_5-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_22-6.start {
    counter-reset: lst-ctn-kix_list_22-6 0;
  }
  .lst-kix_list_19-8 {
    >li {
      counter-increment: lst-ctn-kix_list_19-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_19-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_2-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_2-3;
    }
  }
  .lst-kix_list_11-8 {
    >li {
      counter-increment: lst-ctn-kix_list_11-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_11-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_24-8.start {
    counter-reset: lst-ctn-kix_list_24-8 0;
  }
  .lst-kix_list_20-8 {
    >li {
      counter-increment: lst-ctn-kix_list_20-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_20-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_9-1 {
    >li {
      counter-increment: lst-ctn-kix_list_9-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_9-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_3-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_3-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_3-2;
    }
  }
  .lst-kix_list_8-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_8-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_8-1;
    }
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  .lst-kix_list_6-0 {
    >li {
      counter-increment: lst-ctn-kix_list_6-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_6-0, decimal) ". ";
      }
    }
  }
  .lst-kix_list_30-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_30-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_30-7;
    }
  }
  ol.lst-kix_list_30-8.start {
    counter-reset: lst-ctn-kix_list_30-8 0;
  }
  ol.lst-kix_list_11-5.start {
    counter-reset: lst-ctn-kix_list_11-5 0;
  }
  .lst-kix_list_11-1 {
    >li {
      counter-increment: lst-ctn-kix_list_11-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_11-1, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_16-6.start {
    counter-reset: lst-ctn-kix_list_16-6 0;
  }
  ol.lst-kix_list_22-1.start {
    counter-reset: lst-ctn-kix_list_22-1 0;
  }
  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0;
  }
  ol.lst-kix_list_16-0.start {
    counter-reset: lst-ctn-kix_list_16-0 0;
  }
  .lst-kix_list_33-4 {
    >li {
      counter-increment: lst-ctn-kix_list_33-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_33-4, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_27-2.start {
    counter-reset: lst-ctn-kix_list_27-2 0;
  }
  ol.lst-kix_list_18-7.start {
    counter-reset: lst-ctn-kix_list_18-7 0;
  }
  ol.lst-kix_list_30-2.start {
    counter-reset: lst-ctn-kix_list_30-2 0;
  }
  ol.lst-kix_list_11-6.start {
    counter-reset: lst-ctn-kix_list_11-6 0;
  }
  .lst-kix_list_4-4 {
    >li {
      counter-increment: lst-ctn-kix_list_4-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_4-4, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_29-4.start {
    counter-reset: lst-ctn-kix_list_29-4 0;
  }
  ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0;
  }
  .lst-kix_list_17-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_17-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_17-1;
    }
  }
  .lst-kix_list_31-8 {
    >li {
      counter-increment: lst-ctn-kix_list_31-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_31-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_25-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_25-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_25-3;
    }
  }
  .lst-kix_list_32-3 {
    >li {
      counter-increment: lst-ctn-kix_list_32-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_32-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0;
  }
  .lst-kix_list_16-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_16-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_16-2;
    }
  }
  ol.lst-kix_list_27-1.start {
    counter-reset: lst-ctn-kix_list_27-1 0;
  }
  .lst-kix_list_26-6 {
    >li {
      counter-increment: lst-ctn-kix_list_26-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_26-6, decimal) ". ";
      }
    }
  }
  .lst-kix_list_15-3 {
    >li {
      counter-increment: lst-ctn-kix_list_15-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_15-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_22-2.start {
    counter-reset: lst-ctn-kix_list_22-2 0;
  }
  ol.lst-kix_list_30-3.start {
    counter-reset: lst-ctn-kix_list_30-3 0;
  }
  ol.lst-kix_list_11-0.start {
    counter-reset: lst-ctn-kix_list_11-0 0;
  }
  ol.lst-kix_list_18-8.start {
    counter-reset: lst-ctn-kix_list_18-8 0;
  }
  ol.lst-kix_list_29-3.start {
    counter-reset: lst-ctn-kix_list_29-3 0;
  }
  .lst-kix_list_3-3 {
    >li {
      counter-increment: lst-ctn-kix_list_3-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
      }
    }
  }
  .lst-kix_list_16-4 {
    >li {
      counter-increment: lst-ctn-kix_list_16-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_16-4, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0;
  }
  ol.lst-kix_list_16-5.start {
    counter-reset: lst-ctn-kix_list_16-5 0;
  }
  .lst-kix_list_2-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_2-6;
    }
  }
  ol.lst-kix_list_16-2.start {
    counter-reset: lst-ctn-kix_list_16-2 0;
  }
  .lst-kix_list_14-5 {
    >li {
      counter-increment: lst-ctn-kix_list_14-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_14-5, decimal) ". ";
      }
    }
  }
  .lst-kix_list_7-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_7-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_7-5;
    }
  }
  .lst-kix_list_27-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_27-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_27-6;
    }
  }
  .lst-kix_list_19-5 {
    >li {
      counter-increment: lst-ctn-kix_list_19-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_19-5, decimal) ". ";
      }
    }
  }
  .lst-kix_list_28-2 {
    >li {
      counter-increment: lst-ctn-kix_list_28-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_28-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_30-4.start {
    counter-reset: lst-ctn-kix_list_30-4 0;
  }
  .lst-kix_list_30-0 {
    >li {
      counter-increment: lst-ctn-kix_list_30-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_30-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_11-1.start {
    counter-reset: lst-ctn-kix_list_11-1 0;
  }
  .lst-kix_list_18-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_18-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_18-5;
    }
  }
  .lst-kix_list_6-7 {
    >li {
      counter-increment: lst-ctn-kix_list_6-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_6-7, decimal) ". ";
      }
    }
  }
  .lst-kix_list_26-3 {
    >li {
      counter-increment: lst-ctn-kix_list_26-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_26-3, decimal) ". ";
      }
    }
  }
  .lst-kix_list_10-6 {
    >li {
      counter-increment: lst-ctn-kix_list_10-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
      }
    }
  }
  .lst-kix_list_15-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_15-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_15-6;
    }
  }
  .lst-kix_list_11-4 {
    >li {
      counter-increment: lst-ctn-kix_list_11-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_11-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_29-0 {
    >li {
      counter-increment: lst-ctn-kix_list_29-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_29-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_24-7.start {
    counter-reset: lst-ctn-kix_list_24-7 0;
  }
  .lst-kix_list_31-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_31-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_31-3;
    }
  }
  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0;
  }
  .lst-kix_list_13-7 {
    >li {
      counter-increment: lst-ctn-kix_list_13-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_13-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  .lst-kix_list_20-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_20-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_20-7;
    }
  }
  ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0;
  }
  .lst-kix_list_4-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_4-6;
    }
  }
  ol.lst-kix_list_29-8.start {
    counter-reset: lst-ctn-kix_list_29-8 0;
  }
  .lst-kix_list_25-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_25-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_25-6;
    }
  }
  ol.lst-kix_list_29-5.start {
    counter-reset: lst-ctn-kix_list_29-5 0;
  }
  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0;
  }
  .lst-kix_list_12-2 {
    >li {
      counter-increment: lst-ctn-kix_list_12-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_12-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_29-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_29-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_29-6;
    }
  }
  ol.lst-kix_list_22-0.start {
    counter-reset: lst-ctn-kix_list_22-0 9;
  }
  .lst-kix_list_33-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_33-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_33-3;
    }
  }
  .lst-kix_list_11-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_11-6;
    }
  }
  ol.lst-kix_list_30-6.start {
    counter-reset: lst-ctn-kix_list_30-6 0;
  }
  ol.lst-kix_list_11-3.start {
    counter-reset: lst-ctn-kix_list_11-3 0;
  }
  .lst-kix_list_1-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_1-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_1-2;
    }
  }
  .lst-kix_list_1-0 {
    >li {
      counter-increment: lst-ctn-kix_list_1-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_16-1.start {
    counter-reset: lst-ctn-kix_list_16-1 0;
  }
  li.li-bullet-0 {
    &:before {
      margin-left: -18pt;
      white-space: nowrap;
      display: inline-block;
      min-width: 18pt;
    }
  }
  ol.lst-kix_list_29-7.start {
    counter-reset: lst-ctn-kix_list_29-7 0;
  }
  .lst-kix_list_18-7 {
    >li {
      counter-increment: lst-ctn-kix_list_18-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_18-7, decimal) ". ";
      }
    }
  }
  .lst-kix_list_29-7 {
    >li {
      counter-increment: lst-ctn-kix_list_29-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_29-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_11-4.start {
    counter-reset: lst-ctn-kix_list_11-4 0;
  }
  ol.lst-kix_list_30-7.start {
    counter-reset: lst-ctn-kix_list_30-7 0;
  }
  .lst-kix_list_14-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_14-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_14-1;
    }
  }
  .lst-kix_list_14-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_14-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_14-3;
    }
  }
  ol.lst-kix_list_15-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-8 {
    list-style-type: none;
  }
  .lst-kix_list_14-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_14-0;
    }
  }
  ol.lst-kix_list_15-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_18-5.start {
    counter-reset: lst-ctn-kix_list_18-5 0;
  }
  .lst-kix_list_6-1 {
    >li {
      counter-increment: lst-ctn-kix_list_6-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_6-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_14-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_14-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_14-7;
    }
  }
  ol.lst-kix_list_15-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-0 {
    list-style-type: none;
  }
  .lst-kix_list_14-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_14-6;
    }
  }
  ol.lst-kix_list_15-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-4.start {
    counter-reset: lst-ctn-kix_list_7-4 0;
  }
  .lst-kix_list_17-0 {
    >li {
      counter-increment: lst-ctn-kix_list_17-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_25-3.start {
    counter-reset: lst-ctn-kix_list_25-3 0;
  }
  ol.lst-kix_list_11-8.start {
    counter-reset: lst-ctn-kix_list_11-8 0;
  }
  .lst-kix_list_14-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_14-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_14-2;
    }
  }
  ol.lst-kix_list_32-1.start {
    counter-reset: lst-ctn-kix_list_32-1 0;
  }
  ol.lst-kix_list_26-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-0.start {
    counter-reset: lst-ctn-kix_list_12-0 0;
  }
  ol.lst-kix_list_26-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_28-3.start {
    counter-reset: lst-ctn-kix_list_28-3 0;
  }
  ol.lst-kix_list_26-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_26-0 {
    list-style-type: none;
  }
  .lst-kix_list_32-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_32-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_32-1;
    }
  }
  ol.lst-kix_list_21-6.start {
    counter-reset: lst-ctn-kix_list_21-6 0;
  }
  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0;
  }
  .lst-kix_list_14-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_14-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_14-8;
    }
  }
  .lst-kix_list_31-7 {
    >li {
      counter-increment: lst-ctn-kix_list_31-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_31-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_15-5.start {
    counter-reset: lst-ctn-kix_list_15-5 0;
  }
  ol.lst-kix_list_6-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-3.start {
    counter-reset: lst-ctn-kix_list_22-3 0;
  }
  .lst-kix_list_5-4 {
    >li {
      counter-increment: lst-ctn-kix_list_5-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_5-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_5-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_5-3;
    }
  }
  ol.lst-kix_list_24-6.start {
    counter-reset: lst-ctn-kix_list_24-6 0;
  }
  .lst-kix_list_8-3 {
    >li {
      counter-increment: lst-ctn-kix_list_8-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_8-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_18-0.start {
    counter-reset: lst-ctn-kix_list_18-0 5;
  }
  ol.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-5.start {
    counter-reset: lst-ctn-kix_list_12-5 0;
  }
  ol.lst-kix_list_32-6.start {
    counter-reset: lst-ctn-kix_list_32-6 0;
  }
  .lst-kix_list_6-8 {
    >li {
      counter-increment: lst-ctn-kix_list_6-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_6-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_14-8.start {
    counter-reset: lst-ctn-kix_list_14-8 0;
  }
  .lst-kix_list_6-2 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_6-2, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_6-2;
    }
  }
  ol.lst-kix_list_15-0.start {
    counter-reset: lst-ctn-kix_list_15-0 0;
  }
  .lst-kix_list_2-5 {
    >li {
      counter-increment: lst-ctn-kix_list_2-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_2-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0;
  }
  ol.lst-kix_list_17-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-6.start {
    counter-reset: lst-ctn-kix_list_10-6 0;
  }
  ol.lst-kix_list_27-6.start {
    counter-reset: lst-ctn-kix_list_27-6 0;
  }
  .lst-kix_list_7-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_7-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_7-4;
    }
  }
  .lst-kix_list_7-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_7-6;
    }
  }
  ol.lst-kix_list_17-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-7.start {
    counter-reset: lst-ctn-kix_list_19-7 0;
  }
  ol.lst-kix_list_17-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0;
  }
  .lst-kix_list_15-5 {
    >li {
      counter-increment: lst-ctn-kix_list_15-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_15-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_17-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_24-1.start {
    counter-reset: lst-ctn-kix_list_24-1 0;
  }
  ol.lst-kix_list_17-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-3 {
    list-style-type: none;
  }
  .lst-kix_list_27-2 {
    >li {
      counter-increment: lst-ctn-kix_list_27-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_27-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_31-0 {
    >li {
      counter-increment: lst-ctn-kix_list_31-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_31-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_22-8.start {
    counter-reset: lst-ctn-kix_list_22-8 0;
  }
  .lst-kix_list_12-6 {
    >li {
      counter-increment: lst-ctn-kix_list_12-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_23-0.start {
    counter-reset: lst-ctn-kix_list_23-0 10;
  }
  .lst-kix_list_4-7 {
    >li {
      counter-increment: lst-ctn-kix_list_4-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_4-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }
  ol.lst-kix_list_28-8 {
    list-style-type: none;
  }
  .lst-kix_list_31-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_31-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_31-6;
    }
  }
  ol.lst-kix_list_26-0.start {
    counter-reset: lst-ctn-kix_list_26-0 13;
  }
  .lst-kix_list_13-4 {
    >li {
      counter-increment: lst-ctn-kix_list_13-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_13-4, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_28-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_28-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_28-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_28-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_28-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_28-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_28-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_28-2 {
    list-style-type: none;
  }
  .lst-kix_list_15-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_15-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_15-7;
    }
  }
  .lst-kix_list_17-7 {
    >li {
      counter-increment: lst-ctn-kix_list_17-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_17-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_33-3.start {
    counter-reset: lst-ctn-kix_list_33-3 0;
  }
  .lst-kix_list_4-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_4-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_4-5;
    }
  }
  .lst-kix_list_1-8 {
    >li {
      counter-increment: lst-ctn-kix_list_1-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_1-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_15-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_15-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_15-1;
    }
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  .lst-kix_list_33-1 {
    >li {
      counter-increment: lst-ctn-kix_list_33-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_33-1, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0;
  }
  .lst-kix_list_33-2 {
    >li {
      counter-increment: lst-ctn-kix_list_33-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_33-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_30-2 {
    >li {
      counter-increment: lst-ctn-kix_list_30-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_30-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_9-2.start {
    counter-reset: lst-ctn-kix_list_9-2 0;
  }
  .lst-kix_list_20-0 {
    >li {
      counter-increment: lst-ctn-kix_list_20-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_20-0, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_16-7.start {
    counter-reset: lst-ctn-kix_list_16-7 0;
  }
  .lst-kix_list_32-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_32-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_32-4;
    }
  }
  .lst-kix_list_11-2 {
    >li {
      counter-increment: lst-ctn-kix_list_11-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_11-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_19-2 {
    >li {
      counter-increment: lst-ctn-kix_list_19-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_19-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_33-8 {
    >li {
      counter-increment: lst-ctn-kix_list_33-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_33-8, decimal) ". ";
      }
    }
  }
  .lst-kix_list_30-3 {
    >li {
      counter-increment: lst-ctn-kix_list_30-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_30-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-4.start {
    counter-reset: lst-ctn-kix_list_31-4 0;
  }
  ol.lst-kix_list_8-4 {
    list-style-type: none;
  }
  .lst-kix_list_12-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_12-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_12-1;
    }
  }
  ol.lst-kix_list_8-5 {
    list-style-type: none;
  }
  .lst-kix_list_33-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_33-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_33-0;
    }
  }
  ol.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-0 {
    list-style-type: none;
  }
  .lst-kix_list_16-3 {
    >li {
      counter-increment: lst-ctn-kix_list_16-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_16-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_8-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-2 {
    list-style-type: none;
  }
  .lst-kix_list_13-3 {
    >li {
      counter-increment: lst-ctn-kix_list_13-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_13-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_13-6.start {
    counter-reset: lst-ctn-kix_list_13-6 0;
  }
  ol.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_25-8.start {
    counter-reset: lst-ctn-kix_list_25-8 0;
  }
  .lst-kix_list_10-4 {
    >li {
      counter-increment: lst-ctn-kix_list_10-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_10-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_13-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_13-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_13-5;
    }
  }
  ol.lst-kix_list_28-8.start {
    counter-reset: lst-ctn-kix_list_28-8 0;
  }
  ol.lst-kix_list_13-7.start {
    counter-reset: lst-ctn-kix_list_13-7 0;
  }
  .lst-kix_list_18-4 {
    >li {
      counter-increment: lst-ctn-kix_list_18-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_18-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_12-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_12-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_12-7;
    }
  }
  .lst-kix_list_29-4 {
    >li {
      counter-increment: lst-ctn-kix_list_29-4;
      &:before {
        content: "" counter(lst-ctn-kix_list_29-4, decimal) ". ";
      }
    }
  }
  .lst-kix_list_33-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_33-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_33-6;
    }
  }
  ol.lst-kix_list_21-1.start {
    counter-reset: lst-ctn-kix_list_21-1 0;
  }
  ol.lst-kix_list_29-0.start {
    counter-reset: lst-ctn-kix_list_29-0 16;
  }
  .lst-kix_list_32-5 {
    >li {
      counter-increment: lst-ctn-kix_list_32-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_32-5, decimal) ". ";
      }
    }
  }
  .lst-kix_list_30-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_30-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_30-5;
    }
  }
  ol.lst-kix_list_11-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_33-2.start {
    counter-reset: lst-ctn-kix_list_33-2 0;
  }
  .lst-kix_list_1-1 {
    >li {
      counter-increment: lst-ctn-kix_list_1-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_1-1, decimal) ". ";
      }
    }
  }
  .lst-kix_list_30-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_30-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_30-1;
    }
  }
  ol.lst-kix_list_11-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }
  ol.lst-kix_list_11-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-5.start {
    counter-reset: lst-ctn-kix_list_20-5 0;
  }
  ol.lst-kix_list_13-1.start {
    counter-reset: lst-ctn-kix_list_13-1 0;
  }
  ol.lst-kix_list_11-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-1 {
    list-style-type: none;
  }
  .lst-kix_list_4-0 {
    >li {
      counter-increment: lst-ctn-kix_list_4-0;
      &:before {
        content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
      }
    }
  }
  .lst-kix_list_3-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_3-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_3-4;
    }
  }
  .lst-kix_list_3-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_3-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_3-8;
    }
  }
  ol.lst-kix_list_10-7.start {
    counter-reset: lst-ctn-kix_list_10-7 0;
  }
  ol.lst-kix_list_22-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-2 {
    list-style-type: none;
  }
  .lst-kix_list_8-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_8-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_8-4;
    }
  }
  ol.lst-kix_list_22-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_22-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-5.start {
    counter-reset: lst-ctn-kix_list_8-5 0;
  }
  ol.lst-kix_list_26-4.start {
    counter-reset: lst-ctn-kix_list_26-4 0;
  }
  ol.lst-kix_list_31-0.start {
    counter-reset: lst-ctn-kix_list_31-0 18;
  }
  ol.lst-kix_list_9-3.start {
    counter-reset: lst-ctn-kix_list_9-3 0;
  }
  .lst-kix_list_8-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_8-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_8-8;
    }
  }
  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_17-8 {
    >li {
      counter-increment: lst-ctn-kix_list_17-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_17-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_20-0.start {
    counter-reset: lst-ctn-kix_list_20-0 7;
  }
  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0;
  }
  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0;
  }
  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_18-1.start {
    counter-reset: lst-ctn-kix_list_18-1 0;
  }
  ol.lst-kix_list_8-6.start {
    counter-reset: lst-ctn-kix_list_8-6 0;
  }
  .lst-kix_list_17-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_17-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_17-4;
    }
  }
  ol.lst-kix_list_8-0.start {
    counter-reset: lst-ctn-kix_list_8-0 0;
  }
  .lst-kix_list_7-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_7-0;
    }
  }
  ol.lst-kix_list_27-7.start {
    counter-reset: lst-ctn-kix_list_27-7 0;
  }
  ol.lst-kix_list_19-6.start {
    counter-reset: lst-ctn-kix_list_19-6 0;
  }
  ol.lst-kix_list_9-7.start {
    counter-reset: lst-ctn-kix_list_9-7 0;
  }
  ol.lst-kix_list_13-8 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_2-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_2-4;
    }
  }
  ol.lst-kix_list_13-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_13-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-3 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_7-3;
    }
  }
  ol.lst-kix_list_13-3 {
    list-style-type: none;
  }
  .lst-kix_list_9-7 {
    >li {
      counter-increment: lst-ctn-kix_list_9-7;
      &:before {
        content: "" counter(lst-ctn-kix_list_9-7, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_21-7.start {
    counter-reset: lst-ctn-kix_list_21-7 0;
  }
  .lst-kix_list_18-6 {
    >li {
      counter-increment: lst-ctn-kix_list_18-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_18-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0;
  }
  .lst-kix_list_7-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_7-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_7-7;
    }
  }
  ol.lst-kix_list_8-1.start {
    counter-reset: lst-ctn-kix_list_8-1 0;
  }
  .lst-kix_list_15-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_15-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_15-4;
    }
  }
  ol.lst-kix_list_19-1.start {
    counter-reset: lst-ctn-kix_list_19-1 0;
  }
  ol.lst-kix_list_24-8 {
    list-style-type: none;
  }
  .lst-kix_list_10-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_10-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_10-8;
    }
  }
  ol.lst-kix_list_20-4.start {
    counter-reset: lst-ctn-kix_list_20-4 0;
  }
  ol.lst-kix_list_24-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_24-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_24-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_25-2.start {
    counter-reset: lst-ctn-kix_list_25-2 0;
  }
  .lst-kix_list_15-8 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_15-8, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_15-8;
    }
  }
  ol.lst-kix_list_24-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_24-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_14-3.start {
    counter-reset: lst-ctn-kix_list_14-3 0;
  }
  ol.lst-kix_list_24-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_24-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_24-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 0;
  }
  ol.lst-kix_list_32-5.start {
    counter-reset: lst-ctn-kix_list_32-5 0;
  }
  .lst-kix_list_12-8 {
    >li {
      counter-increment: lst-ctn-kix_list_12-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_12-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_13-2.start {
    counter-reset: lst-ctn-kix_list_13-2 0;
  }
  ol.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_33-7.start {
    counter-reset: lst-ctn-kix_list_33-7 0;
  }
  ol.lst-kix_list_14-4.start {
    counter-reset: lst-ctn-kix_list_14-4 0;
  }
  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0;
  }
  ol.lst-kix_list_32-2.start {
    counter-reset: lst-ctn-kix_list_32-2 0;
  }
  .lst-kix_list_12-4 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_12-4, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_12-4;
    }
  }
  ol.lst-kix_list_4-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-2.start {
    counter-reset: lst-ctn-kix_list_19-2 0;
  }
  ol.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 1;
  }
  ol.lst-kix_list_4-6 {
    list-style-type: none;
  }
  .lst-kix_list_12-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_12-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_12-0;
    }
  }
  ol.lst-kix_list_4-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-6 {
    >li {
      counter-increment: lst-ctn-kix_list_1-6;
      &:before {
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_26-5.start {
    counter-reset: lst-ctn-kix_list_26-5 0;
  }
  ol.lst-kix_list_19-3.start {
    counter-reset: lst-ctn-kix_list_19-3 0;
  }
  .lst-kix_list_2-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_2-0;
    }
  }
  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }
  ol.lst-kix_list_33-8.start {
    counter-reset: lst-ctn-kix_list_33-8 0;
  }
  ol.lst-kix_list_9-8.start {
    counter-reset: lst-ctn-kix_list_9-8 0;
  }
  .lst-kix_list_8-2 {
    >li {
      counter-increment: lst-ctn-kix_list_8-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_8-2, decimal) ". ";
      }
    }
  }
  .lst-kix_list_19-0 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_19-0, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_19-0;
    }
  }
  ol.lst-kix_list_17-7.start {
    counter-reset: lst-ctn-kix_list_17-7 0;
  }
  ol.lst-kix_list_12-6.start {
    counter-reset: lst-ctn-kix_list_12-6 0;
  }
  ol.lst-kix_list_30-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_8-2.start {
    counter-reset: lst-ctn-kix_list_8-2 0;
  }
  ol.lst-kix_list_26-1.start {
    counter-reset: lst-ctn-kix_list_26-1 0;
  }
  ol.lst-kix_list_30-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_30-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0;
  }
  ol.lst-kix_list_21-0.start {
    counter-reset: lst-ctn-kix_list_21-0 8;
  }
  ol.lst-kix_list_30-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_30-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_30-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_30-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_30-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_30-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_31-3.start {
    counter-reset: lst-ctn-kix_list_31-3 0;
  }
  ol.lst-kix_list_19-8.start {
    counter-reset: lst-ctn-kix_list_19-8 0;
  }
  ol.lst-kix_list_14-7.start {
    counter-reset: lst-ctn-kix_list_14-7 0;
  }
  ol.lst-kix_list_20-8.start {
    counter-reset: lst-ctn-kix_list_20-8 0;
  }
  .lst-kix_list_19-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_19-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_19-7;
    }
  }
  ol.lst-kix_list_23-8.start {
    counter-reset: lst-ctn-kix_list_23-8 0;
  }
  ol.lst-kix_list_17-2.start {
    counter-reset: lst-ctn-kix_list_17-2 0;
  }
  .lst-kix_list_13-2 {
    >li {
      counter-increment: lst-ctn-kix_list_13-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_13-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_21-5.start {
    counter-reset: lst-ctn-kix_list_21-5 0;
  }
  ol.lst-kix_list_15-6.start {
    counter-reset: lst-ctn-kix_list_15-6 0;
  }
  ol.lst-kix_list_31-8.start {
    counter-reset: lst-ctn-kix_list_31-8 0;
  }
  ol.lst-kix_list_25-4.start {
    counter-reset: lst-ctn-kix_list_25-4 0;
  }
  ol.lst-kix_list_23-1.start {
    counter-reset: lst-ctn-kix_list_23-1 0;
  }
  ol.lst-kix_list_32-0.start {
    counter-reset: lst-ctn-kix_list_32-0 19;
  }
  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }
  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-7 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_2-7, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_2-7;
    }
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_32-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_17-0.start {
    counter-reset: lst-ctn-kix_list_17-0 0;
  }
  ol.lst-kix_list_32-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_32-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_32-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_32-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_32-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-3.start {
    counter-reset: lst-ctn-kix_list_10-3 0;
  }
  ol.lst-kix_list_32-0 {
    list-style-type: none;
  }
  .lst-kix_list_22-3 {
    >li {
      counter-increment: lst-ctn-kix_list_22-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_22-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_32-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_32-1 {
    list-style-type: none;
  }
  .lst-kix_list_10-1 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_10-1, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_10-1;
    }
  }
  ol.lst-kix_list_15-1.start {
    counter-reset: lst-ctn-kix_list_15-1 0;
  }
  ol.lst-kix_list_15-4.start {
    counter-reset: lst-ctn-kix_list_15-4 0;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-3.start {
    counter-reset: lst-ctn-kix_list_7-3 0;
  }
  .lst-kix_list_28-1 {
    >li {
      counter-increment: lst-ctn-kix_list_28-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_28-1, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0;
  }
  ol.lst-kix_list_28-7.start {
    counter-reset: lst-ctn-kix_list_28-7 0;
  }
  .lst-kix_list_29-5 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_29-5, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_29-5;
    }
  }
  ol.lst-kix_list_10-7 {
    list-style-type: none;
  }
  .lst-kix_list_9-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_9-6;
    }
  }
  ol.lst-kix_list_10-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-3 {
    list-style-type: none;
  }
  .lst-kix_list_30-8 {
    >li {
      counter-increment: lst-ctn-kix_list_30-8;
      &:before {
        content: "" counter(lst-ctn-kix_list_30-8, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_10-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-6 {
    list-style-type: none;
  }
  .lst-kix_list_20-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_20-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_20-6;
    }
  }
  ol.lst-kix_list_10-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_10-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-1.start {
    counter-reset: lst-ctn-kix_list_12-1 0;
  }
  ol.lst-kix_list_21-2.start {
    counter-reset: lst-ctn-kix_list_21-2 0;
  }
  .lst-kix_list_8-5 {
    >li {
      counter-increment: lst-ctn-kix_list_8-5;
      &:before {
        content: "" counter(lst-ctn-kix_list_8-5, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_25-7.start {
    counter-reset: lst-ctn-kix_list_25-7 0;
  }
  ol.lst-kix_list_10-5.start {
    counter-reset: lst-ctn-kix_list_10-5 0;
  }
  ol.lst-kix_list_21-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_21-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }
  ol.lst-kix_list_21-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_21-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_21-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_21-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_21-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-5.start {
    counter-reset: lst-ctn-kix_list_7-5 0;
  }
  ol.lst-kix_list_31-5.start {
    counter-reset: lst-ctn-kix_list_31-5 0;
  }
  ol.lst-kix_list_21-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_21-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-7 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 {
    >li {
      counter-increment: lst-ctn-kix_list_3-1;
      &:before {
        content: "" counter(lst-ctn-kix_list_3-1, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_19-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_19-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_7-7.start {
    counter-reset: lst-ctn-kix_list_7-7 0;
  }
  ol.lst-kix_list_17-4.start {
    counter-reset: lst-ctn-kix_list_17-4 0;
  }
  ol.lst-kix_list_31-6.start {
    counter-reset: lst-ctn-kix_list_31-6 0;
  }
  ol.lst-kix_list_12-3.start {
    counter-reset: lst-ctn-kix_list_12-3 0;
  }
  ol.lst-kix_list_28-0.start {
    counter-reset: lst-ctn-kix_list_28-0 15;
  }
  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 4;
  }
  ol.lst-kix_list_31-7.start {
    counter-reset: lst-ctn-kix_list_31-7 0;
  }
  ol.lst-kix_list_12-4.start {
    counter-reset: lst-ctn-kix_list_12-4 0;
  }
  .lst-kix_list_16-6 {
    >li {
      &:before {
        content: "" counter(lst-ctn-kix_list_16-6, decimal) ". ";
      }
      counter-increment: lst-ctn-kix_list_16-6;
    }
  }
  ol.lst-kix_list_10-1.start {
    counter-reset: lst-ctn-kix_list_10-1 0;
  }
  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0;
  }
  ol.lst-kix_list_28-6.start {
    counter-reset: lst-ctn-kix_list_28-6 0;
  }
  ol.lst-kix_list_7-8.start {
    counter-reset: lst-ctn-kix_list_7-8 0;
  }
  ol.lst-kix_list_28-5.start {
    counter-reset: lst-ctn-kix_list_28-5 0;
  }
  ol.lst-kix_list_10-2.start {
    counter-reset: lst-ctn-kix_list_10-2 0;
  }
  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0;
  }
  ol.lst-kix_list_17-3.start {
    counter-reset: lst-ctn-kix_list_17-3 0;
  }
  .lst-kix_list_29-2 {
    >li {
      counter-increment: lst-ctn-kix_list_29-2;
      &:before {
        content: "" counter(lst-ctn-kix_list_29-2, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_28-4.start {
    counter-reset: lst-ctn-kix_list_28-4 0;
  }
  ol.lst-kix_list_23-3.start {
    counter-reset: lst-ctn-kix_list_23-3 0;
  }
  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0;
  }
  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0;
  }
  .lst-kix_list_20-3 {
    >li {
      counter-increment: lst-ctn-kix_list_20-3;
      &:before {
        content: "" counter(lst-ctn-kix_list_20-3, decimal) ". ";
      }
    }
  }
  ol.lst-kix_list_28-1.start {
    counter-reset: lst-ctn-kix_list_28-1 0;
  }
  ol.lst-kix_list_10-0.start {
    counter-reset: lst-ctn-kix_list_10-0 0;
  }
  ol.lst-kix_list_17-8.start {
    counter-reset: lst-ctn-kix_list_17-8 0;
  }
  ol.lst-kix_list_17-5.start {
    counter-reset: lst-ctn-kix_list_17-5 0;
  }
  ol.lst-kix_list_23-6.start {
    counter-reset: lst-ctn-kix_list_23-6 0;
  }
  ol.lst-kix_list_12-7.start {
    counter-reset: lst-ctn-kix_list_12-7 0;
  }
  ol.lst-kix_list_12-8.start {
    counter-reset: lst-ctn-kix_list_12-8 0;
  }
  ol.lst-kix_list_23-5.start {
    counter-reset: lst-ctn-kix_list_23-5 0;
  }
  ol.lst-kix_list_17-6.start {
    counter-reset: lst-ctn-kix_list_17-6 0;
  }
  ol.lst-kix_list_28-2.start {
    counter-reset: lst-ctn-kix_list_28-2 0;
  }
  ol.lst-kix_list_23-4.start {
    counter-reset: lst-ctn-kix_list_23-4 0;
  }
  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table {
    td {
      padding: 0;
    }
    th {
      padding: 0;
    }
  }
  .c2 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c1 {
    background-color: $background-color_1;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c0 {
    color: $color_1;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: $font-family_1;
    font-style: normal;
  }
  .c5 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c6 {
    background-color: $background-color_1;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c3 {
    padding: 0;
    margin: 0;
  }
  .c4 {
    height: 11pt;
  }
  .title {
    padding-top: 24pt;
    color: $color_1;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: $font-family_1;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: $color_2;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: $font-family_2;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: $color_1;
    font-size: 11pt;
    font-family: $font-family_1;
  }
  p {
    margin: 0;
    color: $color_1;
    font-size: 11pt;
    font-family: $font-family_1;
  }
  h1 {
    padding-top: 24pt;
    color: $color_1;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: $font-family_1;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: $color_1;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: $font-family_1;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: $color_1;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: $font-family_1;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: $color_1;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: $font-family_1;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: $color_1;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: $font-family_1;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: $color_1;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: $font-family_1;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  
}