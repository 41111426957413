@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

$color_1: #000000;
$color_2: #666666;
$font-family_1: "Calibri";
$font-family_2: "Georgia";
$background-color_1: #ffffff;

.privacyPolicy{
	ol.lst-kix_list_7-0 {
		list-style-type: none;
	}
	.lst-kix_list_14-1 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-1, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-1;
		}
	}
	.lst-kix_list_14-3 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-3, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-3;
		}
	}
	.lst-kix_list_2-1 {
		>li {
			counter-increment: lst-ctn-kix_list_2-1;
			&:before {
				content: "" counter(lst-ctn-kix_list_2-1, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_9-0.start {
		counter-reset: lst-ctn-kix_list_9-0 8;
	}
	.lst-kix_list_14-0 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-0, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-0;
		}
	}
	.lst-kix_list_14-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-4;
		}
	}
	.lst-kix_list_6-1 {
		>li {
			counter-increment: lst-ctn-kix_list_6-1;
			&:before {
				content: "" counter(lst-ctn-kix_list_6-1, decimal) ". ";
			}
		}
	}
	.lst-kix_list_14-5 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-5;
		}
	}
	.lst-kix_list_14-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-7;
		}
	}
	ol.lst-kix_list_13-4.start {
		counter-reset: lst-ctn-kix_list_13-4 0;
	}
	.lst-kix_list_14-6 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-6;
		}
	}
	.lst-kix_list_13-0 {
		>li {
			counter-increment: lst-ctn-kix_list_13-0;
			&:before {
				content: "" counter(lst-ctn-kix_list_13-0, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_7-4.start {
		counter-reset: lst-ctn-kix_list_7-4 0;
	}
	.lst-kix_list_5-0 {
		>li {
			counter-increment: lst-ctn-kix_list_5-0;
			&:before {
				content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
			}
		}
	}
	.lst-kix_list_9-0 {
		>li {
			counter-increment: lst-ctn-kix_list_9-0;
			&:before {
				content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_2-3.start {
		counter-reset: lst-ctn-kix_list_2-3 0;
	}
	ol.lst-kix_list_7-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_7-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_7-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_7-8 {
		list-style-type: none;
	}
	ol.lst-kix_list_7-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_7-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_11-8.start {
		counter-reset: lst-ctn-kix_list_11-8 0;
	}
	ol.lst-kix_list_7-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_7-4 {
		list-style-type: none;
	}
	.lst-kix_list_14-2 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-2, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-2;
		}
	}
	ol.lst-kix_list_5-3.start {
		counter-reset: lst-ctn-kix_list_5-3 0;
	}
	ol.lst-kix_list_12-0.start {
		counter-reset: lst-ctn-kix_list_12-0 11;
	}
	.lst-kix_list_4-3 {
		>li {
			counter-increment: lst-ctn-kix_list_4-3;
			&:before {
				content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_3-7.start {
		counter-reset: lst-ctn-kix_list_3-7 0;
	}
	.lst-kix_list_14-8 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_14-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_14-8;
		}
	}
	ol.lst-kix_list_8-8.start {
		counter-reset: lst-ctn-kix_list_8-8 0;
	}
	.lst-kix_list_3-2 {
		>li {
			counter-increment: lst-ctn-kix_list_3-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_3-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_10-4.start {
		counter-reset: lst-ctn-kix_list_10-4 0;
	}
	.lst-kix_list_7-2 {
		>li {
			counter-increment: lst-ctn-kix_list_7-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_7-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_6-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_6-1 {
		list-style-type: none;
	}
	.lst-kix_list_5-4 {
		>li {
			counter-increment: lst-ctn-kix_list_5-4;
			&:before {
				content: "" counter(lst-ctn-kix_list_5-4, decimal) ". ";
			}
		}
	}
	.lst-kix_list_1-4 {
		>li {
			counter-increment: lst-ctn-kix_list_1-4;
			&:before {
				content: "" counter(lst-ctn-kix_list_1-4, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_1-6.start {
		counter-reset: lst-ctn-kix_list_1-6 0;
	}
	.lst-kix_list_5-3 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_5-3;
		}
	}
	ol.lst-kix_list_9-5.start {
		counter-reset: lst-ctn-kix_list_9-5 0;
	}
	.lst-kix_list_5-2 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_5-2, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_5-2;
		}
	}
	.lst-kix_list_8-3 {
		>li {
			counter-increment: lst-ctn-kix_list_8-3;
			&:before {
				content: "" counter(lst-ctn-kix_list_8-3, decimal) ". ";
			}
		}
	}
	.lst-kix_list_5-1 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_5-1, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_5-1;
		}
	}
	.lst-kix_list_5-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_5-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_5-7;
		}
	}
	.lst-kix_list_5-6 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_5-6;
		}
	}
	.lst-kix_list_5-8 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_5-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_5-8;
		}
	}
	.lst-kix_list_9-4 {
		>li {
			counter-increment: lst-ctn-kix_list_9-4;
			&:before {
				content: "" counter(lst-ctn-kix_list_9-4, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_6-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_6-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_6-8 {
		list-style-type: none;
	}
	.lst-kix_list_5-5 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_5-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_5-5;
		}
	}
	ol.lst-kix_list_6-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_6-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_6-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_6-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_14-1.start {
		counter-reset: lst-ctn-kix_list_14-1 0;
	}
	ol.lst-kix_list_12-5.start {
		counter-reset: lst-ctn-kix_list_12-5 0;
	}
	.lst-kix_list_6-3 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_6-3;
		}
	}
	.lst-kix_list_6-5 {
		>li {
			counter-increment: lst-ctn-kix_list_6-5;
			&:before {
				content: "" counter(lst-ctn-kix_list_6-5, decimal) ". ";
			}
		}
	}
	.lst-kix_list_6-8 {
		>li {
			counter-increment: lst-ctn-kix_list_6-8;
			&:before {
				content: "" counter(lst-ctn-kix_list_6-8, decimal) ". ";
			}
		}
	}
	.lst-kix_list_6-0 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_6-0, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_6-0;
		}
	}
	.lst-kix_list_6-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_6-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_6-4;
		}
	}
	ol.lst-kix_list_14-8.start {
		counter-reset: lst-ctn-kix_list_14-8 0;
	}
	.lst-kix_list_3-6 {
		>li {
			counter-increment: lst-ctn-kix_list_3-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
			}
		}
	}
	.lst-kix_list_6-2 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_6-2, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_6-2;
		}
	}
	.lst-kix_list_2-5 {
		>li {
			counter-increment: lst-ctn-kix_list_2-5;
			&:before {
				content: "" counter(lst-ctn-kix_list_2-5, decimal) ". ";
			}
		}
	}
	.lst-kix_list_2-8 {
		>li {
			counter-increment: lst-ctn-kix_list_2-8;
			&:before {
				content: "" counter(lst-ctn-kix_list_2-8, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_3-2.start {
		counter-reset: lst-ctn-kix_list_3-2 0;
	}
	.lst-kix_list_6-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_6-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_6-7;
		}
	}
	.lst-kix_list_6-6 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_6-6;
		}
	}
	ol.lst-kix_list_10-6.start {
		counter-reset: lst-ctn-kix_list_10-6 0;
	}
	.lst-kix_list_7-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_7-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_7-4;
		}
	}
	.lst-kix_list_7-6 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_7-6;
		}
	}
	ol.lst-kix_list_6-2.start {
		counter-reset: lst-ctn-kix_list_6-2 0;
	}
	.lst-kix_list_8-6 {
		>li {
			counter-increment: lst-ctn-kix_list_8-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
			}
		}
	}
	.lst-kix_list_12-6 {
		>li {
			counter-increment: lst-ctn-kix_list_12-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_4-6.start {
		counter-reset: lst-ctn-kix_list_4-6 0;
	}
	ol.lst-kix_list_9-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_9-8 {
		list-style-type: none;
	}
	.lst-kix_list_13-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_13-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_13-7;
		}
	}
	ol.lst-kix_list_3-0.start {
		counter-reset: lst-ctn-kix_list_3-0 2;
	}
	ol.lst-kix_list_9-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_9-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_9-5 {
		list-style-type: none;
	}
	.lst-kix_list_7-8 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_7-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_7-8;
		}
	}
	ol.lst-kix_list_9-6 {
		list-style-type: none;
	}
	.lst-kix_list_4-7 {
		>li {
			counter-increment: lst-ctn-kix_list_4-7;
			&:before {
				content: "" counter(lst-ctn-kix_list_4-7, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_9-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_9-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_9-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-5.start {
		counter-reset: lst-ctn-kix_list_2-5 0;
	}
	.lst-kix_list_9-8 {
		>li {
			counter-increment: lst-ctn-kix_list_9-8;
			&:before {
				content: "" counter(lst-ctn-kix_list_9-8, decimal) ". ";
			}
		}
	}
	.lst-kix_list_13-4 {
		>li {
			counter-increment: lst-ctn-kix_list_13-4;
			&:before {
				content: "" counter(lst-ctn-kix_list_13-4, decimal) ". ";
			}
		}
	}
	.lst-kix_list_4-1 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_4-1, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_4-1;
		}
	}
	.lst-kix_list_4-5 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_4-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_4-5;
		}
	}
	.lst-kix_list_1-8 {
		>li {
			counter-increment: lst-ctn-kix_list_1-8;
			&:before {
				content: "" counter(lst-ctn-kix_list_1-8, decimal) ". ";
			}
		}
	}
	.lst-kix_list_10-5 {
		>li {
			counter-increment: lst-ctn-kix_list_10-5;
			&:before {
				content: "" counter(lst-ctn-kix_list_10-5, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_1-4.start {
		counter-reset: lst-ctn-kix_list_1-4 0;
	}
	.lst-kix_list_3-5 {
		>li {
			counter-increment: lst-ctn-kix_list_3-5;
			&:before {
				content: "" counter(lst-ctn-kix_list_3-5, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_1-1.start {
		counter-reset: lst-ctn-kix_list_1-1 0;
	}
	ol.lst-kix_list_4-4.start {
		counter-reset: lst-ctn-kix_list_4-4 0;
	}
	ol.lst-kix_list_9-2.start {
		counter-reset: lst-ctn-kix_list_9-2 0;
	}
	.lst-kix_list_9-3 {
		>li {
			counter-increment: lst-ctn-kix_list_9-3;
			&:before {
				content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
			}
		}
	}
	.lst-kix_list_11-2 {
		>li {
			counter-increment: lst-ctn-kix_list_11-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_11-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_2-8.start {
		counter-reset: lst-ctn-kix_list_2-8 0;
	}
	ol.lst-kix_list_8-8 {
		list-style-type: none;
	}
	.lst-kix_list_12-3 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_12-3, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_12-3;
		}
	}
	ol.lst-kix_list_7-6.start {
		counter-reset: lst-ctn-kix_list_7-6 0;
	}
	ol.lst-kix_list_8-4 {
		list-style-type: none;
	}
	.lst-kix_list_12-1 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_12-1, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_12-1;
		}
	}
	ol.lst-kix_list_8-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_8-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_8-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_8-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_8-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_8-2 {
		list-style-type: none;
	}
	.lst-kix_list_13-3 {
		>li {
			counter-increment: lst-ctn-kix_list_13-3;
			&:before {
				content: "" counter(lst-ctn-kix_list_13-3, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_13-6.start {
		counter-reset: lst-ctn-kix_list_13-6 0;
	}
	ol.lst-kix_list_8-3 {
		list-style-type: none;
	}
	.lst-kix_list_10-4 {
		>li {
			counter-increment: lst-ctn-kix_list_10-4;
			&:before {
				content: "" counter(lst-ctn-kix_list_10-4, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_5-8.start {
		counter-reset: lst-ctn-kix_list_5-8 0;
	}
	.lst-kix_list_1-3 {
		>li {
			counter-increment: lst-ctn-kix_list_1-3;
			&:before {
				content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
			}
		}
	}
	.lst-kix_list_13-5 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_13-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_13-5;
		}
	}
	.lst-kix_list_12-5 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_12-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_12-5;
		}
	}
	ol.lst-kix_list_13-7.start {
		counter-reset: lst-ctn-kix_list_13-7 0;
	}
	ol.lst-kix_list_12-2.start {
		counter-reset: lst-ctn-kix_list_12-2 0;
	}
	.lst-kix_list_12-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_12-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_12-7;
		}
	}
	ol.lst-kix_list_6-0.start {
		counter-reset: lst-ctn-kix_list_6-0 5;
	}
	.lst-kix_list_4-2 {
		>li {
			counter-increment: lst-ctn-kix_list_4-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_4-2, decimal) ". ";
			}
		}
	}
	.lst-kix_list_13-1 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_13-1, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_13-1;
		}
	}
	ol.lst-kix_list_3-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-4.start {
		counter-reset: lst-ctn-kix_list_3-4 0;
	}
	ol.lst-kix_list_3-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_11-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_11-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_11-8 {
		list-style-type: none;
	}
	.lst-kix_list_1-1 {
		>li {
			counter-increment: lst-ctn-kix_list_1-1;
			&:before {
				content: "" counter(lst-ctn-kix_list_1-1, decimal) ". ";
			}
		}
	}
	.lst-kix_list_7-1 {
		>li {
			counter-increment: lst-ctn-kix_list_7-1;
			&:before {
				content: "" counter(lst-ctn-kix_list_7-1, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_11-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_11-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-6.start {
		counter-reset: lst-ctn-kix_list_2-6 0;
	}
	.lst-kix_list_3-0 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-0;
		}
	}
	ol.lst-kix_list_11-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_11-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-1.start {
		counter-reset: lst-ctn-kix_list_13-1 0;
	}
	ol.lst-kix_list_11-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_11-1 {
		list-style-type: none;
	}
	.lst-kix_list_4-0 {
		>li {
			counter-increment: lst-ctn-kix_list_4-0;
			&:before {
				content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
			}
		}
	}
	.lst-kix_list_8-0 {
		>li {
			counter-increment: lst-ctn-kix_list_8-0;
			&:before {
				content: "" counter(lst-ctn-kix_list_8-0, decimal) ". ";
			}
		}
	}
	.lst-kix_list_10-0 {
		>li {
			counter-increment: lst-ctn-kix_list_10-0;
			&:before {
				content: "" counter(lst-ctn-kix_list_10-0, decimal) ". ";
			}
		}
	}
	.lst-kix_list_3-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_3-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-4;
		}
	}
	.lst-kix_list_3-3 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-3;
		}
	}
	ol.lst-kix_list_3-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-8 {
		list-style-type: none;
	}
	.lst-kix_list_8-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_8-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_8-7;
		}
	}
	.lst-kix_list_3-8 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_3-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-8;
		}
	}
	ol.lst-kix_list_10-7.start {
		counter-reset: lst-ctn-kix_list_10-7 0;
	}
	.lst-kix_list_3-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_3-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_3-7;
		}
	}
	.lst-kix_list_8-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_8-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_8-4;
		}
	}
	.lst-kix_list_10-2 {
		>li {
			counter-increment: lst-ctn-kix_list_10-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_10-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_8-5.start {
		counter-reset: lst-ctn-kix_list_8-5 0;
	}
	.lst-kix_list_11-1 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_11-1, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_11-1;
		}
	}
	.lst-kix_list_11-0 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_11-0, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_11-0;
		}
	}
	ol.lst-kix_list_9-3.start {
		counter-reset: lst-ctn-kix_list_9-3 0;
	}
	.lst-kix_list_8-8 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_8-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_8-8;
		}
	}
	ol.lst-kix_list_2-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_7-2.start {
		counter-reset: lst-ctn-kix_list_7-2 0;
	}
	ol.lst-kix_list_2-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-1 {
		list-style-type: none;
	}
	.lst-kix_list_4-8 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_4-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_4-8;
		}
	}
	ol.lst-kix_list_12-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-8 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-8.start {
		counter-reset: lst-ctn-kix_list_4-8 0;
	}
	ol.lst-kix_list_3-3.start {
		counter-reset: lst-ctn-kix_list_3-3 0;
	}
	ol.lst-kix_list_10-8.start {
		counter-reset: lst-ctn-kix_list_10-8 0;
	}
	ol.lst-kix_list_2-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-8 {
		list-style-type: none;
	}
	.lst-kix_list_11-3 {
		>li {
			counter-increment: lst-ctn-kix_list_11-3;
			&:before {
				content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_7-1.start {
		counter-reset: lst-ctn-kix_list_7-1 0;
	}
	ol.lst-kix_list_8-6.start {
		counter-reset: lst-ctn-kix_list_8-6 0;
	}
	ol.lst-kix_list_8-0.start {
		counter-reset: lst-ctn-kix_list_8-0 7;
	}
	.lst-kix_list_7-0 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_7-0, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_7-0;
		}
	}
	.lst-kix_list_13-8 {
		>li {
			counter-increment: lst-ctn-kix_list_13-8;
			&:before {
				content: "" counter(lst-ctn-kix_list_13-8, decimal) ". ";
			}
		}
	}
	.lst-kix_list_2-2 {
		>li {
			counter-increment: lst-ctn-kix_list_2-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_2-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_4-7.start {
		counter-reset: lst-ctn-kix_list_4-7 0;
	}
	ol.lst-kix_list_5-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_5-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_9-7.start {
		counter-reset: lst-ctn-kix_list_9-7 0;
	}
	ol.lst-kix_list_5-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-8 {
		list-style-type: none;
	}
	.lst-kix_list_2-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_2-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_2-4;
		}
	}
	ol.lst-kix_list_13-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-2 {
		list-style-type: none;
	}
	.lst-kix_list_7-3 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_7-3;
		}
	}
	ol.lst-kix_list_13-3 {
		list-style-type: none;
	}
	.lst-kix_list_9-7 {
		>li {
			counter-increment: lst-ctn-kix_list_9-7;
			&:before {
				content: "" counter(lst-ctn-kix_list_9-7, decimal) ". ";
			}
		}
	}
	.lst-kix_list_13-6 {
		>li {
			counter-increment: lst-ctn-kix_list_13-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_13-6, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_14-6.start {
		counter-reset: lst-ctn-kix_list_14-6 0;
	}
	ol.lst-kix_list_5-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_5-8 {
		list-style-type: none;
	}
	ol.lst-kix_list_5-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_5-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-8.start {
		counter-reset: lst-ctn-kix_list_3-8 0;
	}
	ol.lst-kix_list_5-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_5-6 {
		list-style-type: none;
	}
	.lst-kix_list_7-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_7-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_7-7;
		}
	}
	ol.lst-kix_list_8-1.start {
		counter-reset: lst-ctn-kix_list_8-1 0;
	}
	.lst-kix_list_9-5 {
		>li {
			counter-increment: lst-ctn-kix_list_9-5;
			&:before {
				content: "" counter(lst-ctn-kix_list_9-5, decimal) ". ";
			}
		}
	}
	.lst-kix_list_10-8 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_10-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_10-8;
		}
	}
	ol.lst-kix_list_14-3.start {
		counter-reset: lst-ctn-kix_list_14-3 0;
	}
	.lst-kix_list_4-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_4-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_4-4;
		}
	}
	ol.lst-kix_list_2-2.start {
		counter-reset: lst-ctn-kix_list_2-2 0;
	}
	ol.lst-kix_list_7-0.start {
		counter-reset: lst-ctn-kix_list_7-0 6;
	}
	.lst-kix_list_12-8 {
		>li {
			counter-increment: lst-ctn-kix_list_12-8;
			&:before {
				content: "" counter(lst-ctn-kix_list_12-8, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_13-2.start {
		counter-reset: lst-ctn-kix_list_13-2 0;
	}
	ol.lst-kix_list_4-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_14-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_14-4.start {
		counter-reset: lst-ctn-kix_list_14-4 0;
	}
	ol.lst-kix_list_14-8 {
		list-style-type: none;
	}
	ol.lst-kix_list_14-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_3-6.start {
		counter-reset: lst-ctn-kix_list_3-6 0;
	}
	ol.lst-kix_list_14-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_14-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_14-6 {
		list-style-type: none;
	}
	.lst-kix_list_11-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_11-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_11-4;
		}
	}
	ol.lst-kix_list_14-0 {
		list-style-type: none;
	}
	.lst-kix_list_12-4 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_12-4, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_12-4;
		}
	}
	ol.lst-kix_list_14-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_14-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_4-8 {
		list-style-type: none;
	}
	.lst-kix_list_1-0 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_1-0;
		}
	}
	ol.lst-kix_list_4-4 {
		list-style-type: none;
	}
	.lst-kix_list_11-8 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_11-8, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_11-8;
		}
	}
	ol.lst-kix_list_4-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_2-0.start {
		counter-reset: lst-ctn-kix_list_2-0 1;
	}
	ol.lst-kix_list_4-6 {
		list-style-type: none;
	}
	.lst-kix_list_12-0 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_12-0, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_12-0;
		}
	}
	ol.lst-kix_list_4-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_8-4.start {
		counter-reset: lst-ctn-kix_list_8-4 0;
	}
	ol.lst-kix_list_3-5.start {
		counter-reset: lst-ctn-kix_list_3-5 0;
	}
	.lst-kix_list_1-6 {
		>li {
			counter-increment: lst-ctn-kix_list_1-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_13-0.start {
		counter-reset: lst-ctn-kix_list_13-0 12;
	}
	.lst-kix_list_10-7 {
		>li {
			counter-increment: lst-ctn-kix_list_10-7;
			&:before {
				content: "" counter(lst-ctn-kix_list_10-7, decimal) ". ";
			}
		}
	}
	.lst-kix_list_2-0 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_2-0;
		}
	}
	ol.lst-kix_list_2-1.start {
		counter-reset: lst-ctn-kix_list_2-1 0;
	}
	ol.lst-kix_list_8-3.start {
		counter-reset: lst-ctn-kix_list_8-3 0;
	}
	.lst-kix_list_11-5 {
		>li {
			counter-increment: lst-ctn-kix_list_11-5;
			&:before {
				content: "" counter(lst-ctn-kix_list_11-5, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_14-5.start {
		counter-reset: lst-ctn-kix_list_14-5 0;
	}
	ol.lst-kix_list_9-8.start {
		counter-reset: lst-ctn-kix_list_9-8 0;
	}
	.lst-kix_list_8-2 {
		>li {
			counter-increment: lst-ctn-kix_list_8-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_8-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_12-6.start {
		counter-reset: lst-ctn-kix_list_12-6 0;
	}
	.lst-kix_list_8-1 {
		>li {
			counter-increment: lst-ctn-kix_list_8-1;
			&:before {
				content: "" counter(lst-ctn-kix_list_8-1, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_8-2.start {
		counter-reset: lst-ctn-kix_list_8-2 0;
	}
	ol.lst-kix_list_3-1.start {
		counter-reset: lst-ctn-kix_list_3-1 0;
	}
	ol.lst-kix_list_6-6.start {
		counter-reset: lst-ctn-kix_list_6-6 0;
	}
	ol.lst-kix_list_1-5.start {
		counter-reset: lst-ctn-kix_list_1-5 0;
	}
	ol.lst-kix_list_9-6.start {
		counter-reset: lst-ctn-kix_list_9-6 0;
	}
	.lst-kix_list_2-3 {
		>li {
			counter-increment: lst-ctn-kix_list_2-3;
			&:before {
				content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_4-5.start {
		counter-reset: lst-ctn-kix_list_4-5 0;
	}
	.lst-kix_list_1-2 {
		>li {
			counter-increment: lst-ctn-kix_list_1-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_1-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_14-7.start {
		counter-reset: lst-ctn-kix_list_14-7 0;
	}
	ol.lst-kix_list_11-2.start {
		counter-reset: lst-ctn-kix_list_11-2 0;
	}
	.lst-kix_list_9-2 {
		>li {
			counter-increment: lst-ctn-kix_list_9-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_9-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_8-7.start {
		counter-reset: lst-ctn-kix_list_8-7 0;
	}
	.lst-kix_list_13-2 {
		>li {
			counter-increment: lst-ctn-kix_list_13-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_13-2, decimal) ". ";
			}
		}
	}
	.lst-kix_list_10-3 {
		>li {
			counter-increment: lst-ctn-kix_list_10-3;
			&:before {
				content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_1-0.start {
		counter-reset: lst-ctn-kix_list_1-0 0;
	}
	ol.lst-kix_list_13-3.start {
		counter-reset: lst-ctn-kix_list_13-3 0;
	}
	ol.lst-kix_list_4-0.start {
		counter-reset: lst-ctn-kix_list_4-0 3;
	}
	ol.lst-kix_list_11-7.start {
		counter-reset: lst-ctn-kix_list_11-7 0;
	}
	ol.lst-kix_list_14-2.start {
		counter-reset: lst-ctn-kix_list_14-2 0;
	}
	ol.lst-kix_list_2-4.start {
		counter-reset: lst-ctn-kix_list_2-4 0;
	}
	ol.lst-kix_list_1-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_1-4 {
		list-style-type: none;
	}
	.lst-kix_list_2-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_2-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_2-7;
		}
	}
	ol.lst-kix_list_1-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_1-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_1-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_1-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_1-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_10-3.start {
		counter-reset: lst-ctn-kix_list_10-3 0;
	}
	ol.lst-kix_list_9-4.start {
		counter-reset: lst-ctn-kix_list_9-4 0;
	}
	.lst-kix_list_10-1 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_10-1, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_10-1;
		}
	}
	ol.lst-kix_list_4-3.start {
		counter-reset: lst-ctn-kix_list_4-3 0;
	}
	ol.lst-kix_list_1-7 {
		list-style-type: none;
	}
	ol.lst-kix_list_1-8 {
		list-style-type: none;
	}
	ol.lst-kix_list_13-5.start {
		counter-reset: lst-ctn-kix_list_13-5 0;
	}
	.lst-kix_list_2-6 {
		>li {
			counter-increment: lst-ctn-kix_list_2-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_7-3.start {
		counter-reset: lst-ctn-kix_list_7-3 0;
	}
	ol.lst-kix_list_13-8.start {
		counter-reset: lst-ctn-kix_list_13-8 0;
	}
	.lst-kix_list_11-7 {
		>li {
			counter-increment: lst-ctn-kix_list_11-7;
			&:before {
				content: "" counter(lst-ctn-kix_list_11-7, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_5-7.start {
		counter-reset: lst-ctn-kix_list_5-7 0;
	}
	ol.lst-kix_list_14-0.start {
		counter-reset: lst-ctn-kix_list_14-0 13;
	}
	ol.lst-kix_list_10-7 {
		list-style-type: none;
	}
	.lst-kix_list_9-6 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_9-6;
		}
	}
	ol.lst-kix_list_10-8 {
		list-style-type: none;
	}
	ol.lst-kix_list_10-3 {
		list-style-type: none;
	}
	ol.lst-kix_list_10-4 {
		list-style-type: none;
	}
	ol.lst-kix_list_10-5 {
		list-style-type: none;
	}
	ol.lst-kix_list_10-6 {
		list-style-type: none;
	}
	ol.lst-kix_list_10-0 {
		list-style-type: none;
	}
	ol.lst-kix_list_10-1 {
		list-style-type: none;
	}
	ol.lst-kix_list_1-3.start {
		counter-reset: lst-ctn-kix_list_1-3 0;
	}
	ol.lst-kix_list_10-2 {
		list-style-type: none;
	}
	ol.lst-kix_list_12-1.start {
		counter-reset: lst-ctn-kix_list_12-1 0;
	}
	ol.lst-kix_list_1-2.start {
		counter-reset: lst-ctn-kix_list_1-2 0;
	}
	ol.lst-kix_list_6-1.start {
		counter-reset: lst-ctn-kix_list_6-1 0;
	}
	.lst-kix_list_8-5 {
		>li {
			counter-increment: lst-ctn-kix_list_8-5;
			&:before {
				content: "" counter(lst-ctn-kix_list_8-5, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_10-5.start {
		counter-reset: lst-ctn-kix_list_10-5 0;
	}
	.lst-kix_list_1-7 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_1-7, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_1-7;
		}
	}
	ol.lst-kix_list_2-7.start {
		counter-reset: lst-ctn-kix_list_2-7 0;
	}
	.lst-kix_list_1-5 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_1-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_1-5;
		}
	}
	ol.lst-kix_list_9-1.start {
		counter-reset: lst-ctn-kix_list_9-1 0;
	}
	ol.lst-kix_list_7-5.start {
		counter-reset: lst-ctn-kix_list_7-5 0;
	}
	.lst-kix_list_3-1 {
		>li {
			counter-increment: lst-ctn-kix_list_3-1;
			&:before {
				content: "" counter(lst-ctn-kix_list_3-1, decimal) ". ";
			}
		}
	}
	.lst-kix_list_9-1 {
		>li {
			counter-increment: lst-ctn-kix_list_9-1;
			&:before {
				content: "" counter(lst-ctn-kix_list_9-1, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_7-7.start {
		counter-reset: lst-ctn-kix_list_7-7 0;
	}
	ol.lst-kix_list_1-8.start {
		counter-reset: lst-ctn-kix_list_1-8 0;
	}
	ol.lst-kix_list_12-3.start {
		counter-reset: lst-ctn-kix_list_12-3 0;
	}
	ol.lst-kix_list_11-5.start {
		counter-reset: lst-ctn-kix_list_11-5 0;
	}
	ol.lst-kix_list_5-0.start {
		counter-reset: lst-ctn-kix_list_5-0 4;
	}
	ol.lst-kix_list_4-2.start {
		counter-reset: lst-ctn-kix_list_4-2 0;
	}
	ol.lst-kix_list_11-6.start {
		counter-reset: lst-ctn-kix_list_11-6 0;
	}
	ol.lst-kix_list_12-4.start {
		counter-reset: lst-ctn-kix_list_12-4 0;
	}
	ol.lst-kix_list_10-1.start {
		counter-reset: lst-ctn-kix_list_10-1 0;
	}
	ol.lst-kix_list_6-4.start {
		counter-reset: lst-ctn-kix_list_6-4 0;
	}
	ol.lst-kix_list_5-6.start {
		counter-reset: lst-ctn-kix_list_5-6 0;
	}
	ol.lst-kix_list_4-1.start {
		counter-reset: lst-ctn-kix_list_4-1 0;
	}
	ol.lst-kix_list_7-8.start {
		counter-reset: lst-ctn-kix_list_7-8 0;
	}
	ol.lst-kix_list_11-0.start {
		counter-reset: lst-ctn-kix_list_11-0 10;
	}
	ol.lst-kix_list_6-3.start {
		counter-reset: lst-ctn-kix_list_6-3 0;
	}
	ol.lst-kix_list_10-2.start {
		counter-reset: lst-ctn-kix_list_10-2 0;
	}
	ol.lst-kix_list_5-5.start {
		counter-reset: lst-ctn-kix_list_5-5 0;
	}
	.lst-kix_list_7-5 {
		>li {
			&:before {
				content: "" counter(lst-ctn-kix_list_7-5, decimal) ". ";
			}
			counter-increment: lst-ctn-kix_list_7-5;
		}
	}
	ol.lst-kix_list_5-4.start {
		counter-reset: lst-ctn-kix_list_5-4 0;
	}
	ol.lst-kix_list_11-1.start {
		counter-reset: lst-ctn-kix_list_11-1 0;
	}
	ol.lst-kix_list_5-1.start {
		counter-reset: lst-ctn-kix_list_5-1 0;
	}
	.lst-kix_list_10-6 {
		>li {
			counter-increment: lst-ctn-kix_list_10-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
			}
		}
	}
	.lst-kix_list_11-6 {
		>li {
			counter-increment: lst-ctn-kix_list_11-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_10-0.start {
		counter-reset: lst-ctn-kix_list_10-0 9;
	}
	ol.lst-kix_list_6-8.start {
		counter-reset: lst-ctn-kix_list_6-8 0;
	}
	.lst-kix_list_4-6 {
		>li {
			counter-increment: lst-ctn-kix_list_4-6;
			&:before {
				content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_1-7.start {
		counter-reset: lst-ctn-kix_list_1-7 0;
	}
	ol.lst-kix_list_6-5.start {
		counter-reset: lst-ctn-kix_list_6-5 0;
	}
	ol.lst-kix_list_12-7.start {
		counter-reset: lst-ctn-kix_list_12-7 0;
	}
	ol.lst-kix_list_6-7.start {
		counter-reset: lst-ctn-kix_list_6-7 0;
	}
	.lst-kix_list_12-2 {
		>li {
			counter-increment: lst-ctn-kix_list_12-2;
			&:before {
				content: "" counter(lst-ctn-kix_list_12-2, decimal) ". ";
			}
		}
	}
	ol.lst-kix_list_12-8.start {
		counter-reset: lst-ctn-kix_list_12-8 0;
	}
	ol.lst-kix_list_11-3.start {
		counter-reset: lst-ctn-kix_list_11-3 0;
	}
	li.li-bullet-0 {
		&:before {
			margin-left: -18pt;
			white-space: nowrap;
			display: inline-block;
			min-width: 18pt;
		}
	}
	ol.lst-kix_list_11-4.start {
		counter-reset: lst-ctn-kix_list_11-4 0;
	}
	ol.lst-kix_list_5-2.start {
		counter-reset: lst-ctn-kix_list_5-2 0;
	}
	ol {
		margin: 0;
		padding: 0;
	}
	table {
		td {
			padding: 0;
		}
		th {
			padding: 0;
		}
	}
	.c3 {
		margin-left: 36pt;
		padding-top: 0pt;
		padding-left: 0pt;
		padding-bottom: 0pt;
		line-height: 1.0791666666666666;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.c1 {
		color: $color_1;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 12pt;
		font-family: $font-family_1;
		font-style: normal;
	}
	.c0 {
		background-color: $background-color_1;
		padding-top: 0pt;
		padding-bottom: 0pt;
		line-height: 1.0;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.c5 {
		padding-top: 0pt;
		padding-bottom: 8pt;
		line-height: 1.0791666666666666;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.c6 {
		background-color: $background-color_1;
		max-width: 468pt;
		padding: 72pt 72pt 72pt 72pt;
	}
	.c2 {
		padding: 0;
		margin: 0;
	}
	.c4 {
		height: 11pt;
	}
	.title {
		padding-top: 24pt;
		color: $color_1;
		font-weight: 700;
		font-size: 36pt;
		padding-bottom: 6pt;
		font-family: $font-family_1;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	.subtitle {
		padding-top: 18pt;
		color: $color_2;
		font-size: 24pt;
		padding-bottom: 4pt;
		font-family: $font-family_2;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		font-style: italic;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	li {
		color: $color_1;
		font-size: 11pt;
		font-family: $font-family_1;
	}
	p {
		margin: 0;
		color: $color_1;
		font-size: 11pt;
		font-family: $font-family_1;
	}
	h1 {
		padding-top: 24pt;
		color: $color_1;
		font-weight: 700;
		font-size: 24pt;
		padding-bottom: 6pt;
		font-family: $font-family_1;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h2 {
		padding-top: 18pt;
		color: $color_1;
		font-weight: 700;
		font-size: 18pt;
		padding-bottom: 4pt;
		font-family: $font-family_1;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h3 {
		padding-top: 14pt;
		color: $color_1;
		font-weight: 700;
		font-size: 14pt;
		padding-bottom: 4pt;
		font-family: $font-family_1;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h4 {
		padding-top: 12pt;
		color: $color_1;
		font-weight: 700;
		font-size: 12pt;
		padding-bottom: 2pt;
		font-family: $font-family_1;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h5 {
		padding-top: 11pt;
		color: $color_1;
		font-weight: 700;
		font-size: 11pt;
		padding-bottom: 2pt;
		font-family: $font-family_1;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	h6 {
		padding-top: 10pt;
		color: $color_1;
		font-weight: 700;
		font-size: 10pt;
		padding-bottom: 2pt;
		font-family: $font-family_1;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
	
}